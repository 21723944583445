import { Fragment } from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import location from "../../../../assets/icons/location.svg";
import phone from "../../../../assets/icons/phone.svg";
import message from "../../../../assets/icons/message.svg";
import clock from "../../../../assets/icons/clock.svg";
import { useFooterStyles } from "./footer.styles";
import FooterTopCard from "../../../../components/FooterTopCard";
import { primaryColor } from "../../../../helpers/constants";
import { Map, Marker } from "pigeon-maps";

const Footer = () => {
  const classes = useFooterStyles();
  return (
    <Fragment>
      <Box className={classes.footerTop}>
        <Grid className={classes.footerTopContainer} container spacing={2}>
          <Grid item xs={3}>
            <FooterTopCard
              title="ADDRESS"
              subTitle="KICUKIRO - KK 8 AV 92"
              icon={location}
            />
          </Grid>
          <Grid item xs={3}>
            <FooterTopCard
              title="CALL FOR QUERY"
              subTitle="+250 787 832 337"
              icon={phone}
            />
          </Grid>
          <Grid item xs={3}>
            <FooterTopCard
              title="SEND US MESSAGE"
              subTitle="info@cems.rw"
              icon={message}
            />
          </Grid>
          <Grid item xs={3}>
            <FooterTopCard
              title="OPENING HOURS"
              subTitle="08:00 AM - 05:00 PM"
              icon={clock}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.footer}>
        <Grid className={classes.footerContainer} container spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.footerCardHeading}>
              CEMS LTD
            </Typography>
            <Typography className={classes.footerBottomText}>
              A Rwandan private company that works with passion for excellence
              to each specific project in order to successfully handle every
              challenge.
            </Typography>
            <Box>
              <Typography className={classes.footerCardHeading}>
                Quick Links
              </Typography>
              <Typography className={classes.footerBottomText}>
                HOme About US Services Gallery
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.footerMap}>
              <Map
                height="100%"
                defaultCenter={[-1.979133, 30.100724]}
                defaultZoom={16}
              >
                <Marker
                  height={60}
                  width={30}
                  color={primaryColor}
                  anchor={[-1.979133, 30.100724]}
                />
              </Map>
              {/* <GoogleMapReact
                  defaultCenter={{
                    lat: -1.979133,
                    lng: 30.100724,
                  }}
                  defaultZoom={11}
                >
                  <AnyReactComponent
            lat={59.955413}
            lng={30.337844}
            text="My Marker"
          />
                  <Typography>Map</Typography>
                </GoogleMapReact> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.footerBottom}>
        <Typography className={classes.footerBottomText}>
          Copyright © CEMS LTD 2022
        </Typography>
      </Box>
    </Fragment>
  );
};

export default Footer;
