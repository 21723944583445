import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useContactUsStyles = makeStyles((Theme) =>
  createStyles({
    contactUs: {
        width: "100vw",
        height: "80vh"
    },
    contactUsContainer: {
        width: "50%",
        margin: "2rem auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    heading: {
        fontSize: "2rem",
        fontWeight: "bold",
    },
    textInput: {
        width: "90%",
        margin: "1rem auto",
        padding: "2px 10px"
    },
    submitButton: {
        width: "90%",
        margin: "1rem auto",
        color: "white",
        backgroundColor: "black"
    },
  })
);
