import { Grid, List, ListItem, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useAdminStyles } from "./admin.styles";

const AdminLayout = (props) => {
  const { selected } = props;
  const classes = useAdminStyles();
  const navItems = [
    { label: "Home", path: "/admin" },
    { label: "Services", path: "/admin/services" },
    { label: "Categories", path: "/admin/categories" },
    { label: "HardWare", path: "/admin/hardware" },
  ];
  return (
    <Grid className={classes.parentContainer} container spacing={0}>
      <Grid className={classes.sideNav} item xs={2}>
        <List>
          {navItems.map((item, index) => (
            <Link key={index} to={item.path} className={classes.link}>
              <ListItem
                classes={{ selected: classes.listItemSelected }}
                selected={selected === index}
              >
                <ListItemText>{item.label}</ListItemText>
              </ListItem>
            </Link>
          ))}
        </List>
      </Grid>
      <Grid className={classes.rightContainer} item xs={10}>
        {props.children}
      </Grid>
    </Grid>
  );
};

export default AdminLayout;
