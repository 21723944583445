import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  footerLightBg,
  footerPrimaryBg,
  primaryGrey,
} from "../../../../helpers/constants";

export const useFooterStyles = makeStyles((Theme) =>
  createStyles({
    footer: {
      backgroundColor: footerPrimaryBg,
      height: "30rem",
      color: "white",
    },
    footerContainer: {
      width: "90%",
      margin: "0 auto",
    },
    footerCard: {
      // backgroundColor: "orangeRed",
      // borderRadius: "3rem",
    },
    footerCardHeading: {
      fontSize: "2rem",
      margin: "1rem 0",
      fontWeight: "bold",
    },
    footerMap: {
      width: "95%",
      height: "22rem",
      margin: "3rem auto",
      backgroundColor: "rgba(255, 190, 65, 0.08)",
    },
    footerTop: {
      backgroundColor: footerLightBg,
      height: "12rem",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    footerTopContainer: {
      width: "80%",
    },
    footerTopTitle: {
      fontSize: "1.2rem",
      fontWeight: "bold",
    },
    footerSubTitle: {
      fontSize: "1rem",
      color: primaryGrey,
    },
    footerBottom: {
      backgroundColor: "#161223",
      width: "100%",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    footerBottomText: {
      color: "white",
    },
    center: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);
