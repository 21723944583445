import { Typography, Box, Button, TextField } from "@material-ui/core";
import Layout from "../../components/Layout";
import { useContactUsStyles } from './contactus.style'

const ContactUsPage = () => {
  const classes = useContactUsStyles()
  return (
    <Layout select={3}>
      <Box className={classes.contactUs}>
        <Box className={classes.contactUsContainer}>
      <Typography className={classes.heading}>GET IN TOUCH</Typography>
      <TextField id="outlined-basic" className={classes.textInput} placeholder="Name" variant="outlined" />
      <TextField id="outlined-basic" className={classes.textInput} placeholder="E-mail" variant="outlined" />
      <TextField id="outlined-basic" className={classes.textInput} placeholder="Telephone" variant="outlined" />
      <TextField id="outlined-basic" className={classes.textInput} placeholder="Message" variant="outlined" />
      <Button className={classes.submitButton} variant="contained">SUBMIT</Button>
      </Box>
      </Box>
    </Layout>
  );
};

export default ContactUsPage;
