import { Grid, Box, Typography, Button } from "@material-ui/core";
import { useAboutUsStyles } from "./about.styles";
import image2 from "../../../../assets/images/image2.jpeg";
import image3 from "../../../../assets/images/image3.jpeg";
import image1 from "../../../../assets/images/image1.jpeg";

const AboutUs = () => {
  const classes = useAboutUsStyles();
  return (
    <div id="aboutus">
      <Box className={classes.aboutUs}>
        <Grid container spacing={8} className={classes.aboutUsContainer}>
          <Grid item xs={6}>
            <Grid className={classes.black} container spacing={1}>
              <Grid item xs={6}>
                <Box className={classes.tile1}>
                  <img
                    className={classes.imageRight}
                    src={image1}
                    alt="slide1"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className={classes.imagesRight}>
                  <Box className={classes.up}>
                    <img
                      className={classes.headerNavLogo}
                      src={image2}
                      alt="slide1"
                    />
                  </Box>
                  <Box className={classes.down}>
                    <img
                      className={classes.headerNavLogo}
                      src={image3}
                      alt="slide1"
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography className={classes.aboutUsHeading}>
                ABOUT CEMS LTD
              </Typography>
              <Typography className={classes.aboutUsBigText}>
                11 Years of Construction
              </Typography>
              <Typography className={classes.aboutUsBigText}>
                Experience
              </Typography>
              <Typography className={classes.aboutUsParagraph}>
                CEMS LTD (Civil Engineering Multiservices) is a Rwandan private
                company that works with passion for excellence to each specific
                project in order to successfully handle every challenge. It
                provides the best construction experience through relationships
                built on integrity and success built on performance
              </Typography>
              <Typography className={classes.aboutUsParagraph}>
                Our commitment to bring professionalism, good service and trust
                to the building repair service and maintenance, supply,
                installation, plumbing. We take immense pride in sending some fo
                the most proffesional engineers to construct and fix your
                buildings. We have over 10 years of existence so far.
              </Typography>
              <Button variant="contained" className={classes.aboutUsContactBtn}>
                CONTACT US
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default AboutUs;
