import { makeStyles, createStyles } from "@material-ui/core/styles";
import { primaryGrey, primaryBackground } from "../../../../helpers/constants";

export const useGalleryStyles = makeStyles((Theme) =>
  createStyles({
    gallery: {
      height: "150vh",
      paddingTop: "2rem",
      paddingBottom: "2rem",
      backgroundColor: primaryGrey,
    },
    galleryHeader: {
      marginBottom: "1rem",
      fontSize: "2.5rem",
      fontWeight: "bold",
      textAlign: "center",
    },
    galleryContainer: {
      height: "80%",
      width: "80%",
      margin: "0 auto",
    },
    loadingContainer: {
      height: "70vh",
      width: "80%",
      margin: "0 auto",
      textTransform: "lowercase",
      fontStyle: "italic",
      backgroundColor: primaryBackground,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    loadingText: {
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    galleryCategories: {
      display: "flex",
      flexDirection: "row",
      padding: 0,
      backgroundColor: "white",
      alignItems: "center",
      width: "40vw",
      margin: "2.5rem auto",
    },
    listItemSelected: {
      color: "white",
      backgroundColor: "#7dc22b !important",
    },
  })
);
