import { Card, CardMedia, CardContent, Typography } from "@material-ui/core";
import { useServicesStyles } from "../pages/Home/sections/Services/services.styles";
import { useNavigate } from "react-router-dom";

const ServiceCard = (props) => {
  let navigate = useNavigate();
  const classes = useServicesStyles();
  return (
    <Card
      className={classes.serviceCard}
      onClick={() => navigate(`/services/${props.id}`)}
    >
      <CardMedia
        className={classes.paintCardImage}
        component="img"
        height="187"
        image={props?.image}
        alt="paint"
      />
      <CardContent className={classes.cardContent}>
        <Typography className={classes.cardPrimaryLabel}>
          {props.service}
        </Typography>
      </CardContent>
    </Card>
    // <Box className={classes.serviceCard}>
    //   <Box className={classes.serviceCardContent}>
    //     <Avatar
    //       style={{
    //         backgroundColor: props.background,
    //         height: "5rem",
    //         width: "5rem",
    //       }}
    //     >
    //       {props.icon}
    //     </Avatar>
    //     <Typography>{props.service}</Typography>
    //     <Button
    //       className={classes.learnMoreButton}
    //       endIcon={<ChevronRightIcon />}
    //       onClick={() => navigate("/services/123")}
    //     >
    //       Learn More
    //     </Button>
    //   </Box>
    // </Box>
  );
};

export default ServiceCard;
