import { Box, Typography, List, ListItem, ListItemText, Button } from "@material-ui/core";
import { useStyles } from "../style";
import { Link } from 'react-router-dom';
import logo from "../assets/images/logo.png";
import Footer from '../pages/Home/sections/Footer/Footer';

const Layout = (props) => {
    const { select } = props;
    const NavItems = [
        { label: "Home", path: '/' },
        { label: "About Us", path: '#about' },
        { label: "Services", path: '#services' },
    ];
    const classes = useStyles();
    return (
        <>
            <Box className={classes.headerNav}>
                <Box className={classes.headerNavLeft}>
                    <img className={classes.headerNavLogo} src={logo} alt="logo" />
                    <Typography>CEMS LTD</Typography>
                </Box>
                <List className={classes.navList}>
                    {NavItems.map((nav, index) => <Link key={index} to={nav.path} className={classes.link}>
                        <ListItem selected={index === select} classes={{root: classes.root, selected: classes.listItemSelected}}>
                            <ListItemText >{nav.label}</ListItemText>
                        </ListItem>
                    </Link>
                    )}
                    <Button variant="contained" className={classes.contactBtn}>
                        <Link to="/contact" className={classes.contactLink}>CONTACT US</Link>
            </Button>
                </List>
            </Box>
            {props.children}
            <Footer />
        </>
    );
};

export default Layout;
