import Layout from "../../components/Layout";
import { Typography, Box } from "@material-ui/core";
import { useServicesPageStyles } from './service.styles';

const ServicesPage = () => {
  const classes = useServicesPageStyles();
  return (
    <Layout select={2}>
      <Typography>Service Page</Typography>
      <Box className={classes.imageHeader}>
      <Typography>Hii</Typography>
      </Box>
    </Layout>
  );
};

export default ServicesPage;