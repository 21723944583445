export const primaryPurple = "#2C358D";
export const primaryGreen = "#00A44B";
export const primaryOrange = "#ED7D3A";
export const primaryGrey = "#F8F8F8";
export const primaryBackground = "#F5F5F5";
export const primaryTextColor = "#9E9E9E";
export const primaryYellow = "#EEC643";
export const primaryBlue = "#26AAE1";
export const primaryRed = "#EF2D56";
export const primaryColor = "#7dc22b";

export const footerPrimaryBg = "#1c262f";
export const footerLightBg = "#212d37";
// export const SERVER_URL = "https://cemsweb-backend.herokuapp.com";
export const SERVER_URL = "https://cemswebsite.traceup.rw";
// export const SERVER_URL = "http://127.0.0.1:8000";
