import { makeStyles, createStyles } from "@material-ui/core/styles";
import { primaryBackground, primaryColor } from "../../../../helpers/constants";

export const useServicesStyles = makeStyles((Theme) =>
  createStyles({
    services: {
      height: "140vh",
      paddingTop: "2rem",
      backgroundColor: primaryBackground,
    },
    servicesHeader: {
      marginTop: "1rem",
      marginBottom: "4.5rem",
      fontSize: "2.5rem",
      fontWeight: "bold",
      textAlign: "center",
    },
    center: {
      width: "100%",
      height: "3rem",
      display: "flex",
      backgroundColor: "orangeRed",
      justifyContent: "center",
      alignItems: "center",
    },
    f: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      margin: "1.5rem 0",
    },
    serviceCard: {
      height: "17rem",
      width: "18rem",
      backgroundColor: "white",
      borderRadius: "0.5rem",
      margin: "0 1rem",
      display: "inline-block",
      cursor: "pointer",
    },
    serviceCardContent: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
    },
    cardPrimaryLabel: {
      textAlign: "center",
    },
    learnMoreButton: {
      color: primaryColor,
      textTransform: "none",
    },
    withRed: {
      color: "orangeRed",
    },
  })
);
