import { Grid, Typography, Box } from "@material-ui/core";
// import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useFooterStyles } from "../pages/Home/sections/Footer/footer.styles";

const FooterTopCard = (props) => {
  const classes = useFooterStyles();
  return (
    <Grid container>
      <Grid item xs={3}>
        <Box className={classes.center}>
          <object
            data={props.icon}
            height="40"
            width="40"
            style={{ color: "orangered" }}
          >
            svg
          </object>
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Typography className={classes.footerTopTitle}>
          {props.title}
        </Typography>
        <Typography className={classes.footerSubTitle}>
          {props.subTitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FooterTopCard;
