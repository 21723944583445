import { configureStore } from "@reduxjs/toolkit";
import staffReducer from "./staff";
import serviceReducer from "./services";
import imageReducer from "./images";

const store = configureStore({
  reducer: {
    service: serviceReducer,
    staff: staffReducer,
    image: imageReducer,
  },
});

export default store;
