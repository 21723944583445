import { makeStyles, createStyles } from "@material-ui/core/styles";
import { primaryBackground, primaryTextColor } from "../../helpers/constants";

export const useShopPageStyles = makeStyles((Theme) =>
  createStyles({
    shopContainer: {
      backgroundColor: primaryBackground,
      padding: "3rem 2rem"
    },
    productsContainer: {
        // margin: "primaryBackground",
        padding: "3rem 2rem",
        width: "80%",
        margin: "0 auto"
      },
      header: {
        marginBottom: "2.5rem",
        fontSize: "2.5rem",
        fontWeight: "bold",
        textAlign: "center",
      },
      searchContainer: {
        paddingLeft: "3rem"
      },
      categoriesContainer: {
        backgroundColor: "primaryBackground",
        paddingTop: "2rem",
        width: "80%",
        margin: "0 auto"
      },
      paintCard: {
        maxWidth: 345,
        width: "13rem",
        height: "15rem",
        display: "inline-block",
        margin: "2rem"
      },
      paintCardLabel: {
        fontSize: "2rem",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
      paintCardSubTitle: {
        fontSize: "2rem",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
      paintCardImage: {
        height: "70%",
      },
      productSearch: {
        color: primaryTextColor,
        backgroundColor: "white",
        borderRadius: "20px",
        width: "40vw",
        border: "none",
        paddingLeft: "10px",
      },
      cardContent: {
        padding: "5px 10px 2px 10px"
      },
      cardPrimaryLabel: {
        fontSize: "1rem",
        fontWeight: "bold"
      },
      cardSubTitle: {
        fontSize: "0.8rem",
        fontStyle: "italic",
        color: primaryTextColor
      },
      colorBox: {
        height: "2.5rem",
        width: "2.5rem",
        borderRadius: "0.4rem",
        backgroundColor: "cyan"
      },
      partnerImageBox: {
        maxHeight: "20rem",
        maxWidth: "20rem",
        margin: "1rem"
      },
      partnerImage: {
        height: "100%",
        width: "100%"
      },
      patnersContainer: {
        width: "80%",
        height: "80vh",
        margin: "0 auto",
        display: "flex"
      },
      idealContainer: {
        width: "80%",
        margin: "0 auto",
      }
  })
);
