import { makeStyles, createStyles } from "@material-ui/core/styles";
// import { primaryColor } from "./components/colors";

export const useServicesPageStyles = makeStyles((Theme) =>
  createStyles({
    imageHeader: {
      height: "50vh",
      width: "100vw",
      backgroundColor: "black",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    galleryHeader: {
      marginBottom: "1rem",
      fontSize: "2.5rem",
      fontWeight: "bold",
      textAlign: "center",
    },
    serviceTitle: {
      color: "white",
      fontSize: "3rem",
      textAlign: "center",
      textTransform: "upperCase",
    },
    titleContainer: {
      width: "50vw",
      bacgroundColor: "orangeRed",
    },
    descriptionContainer: {
      width: "70%",
      margin: "3rem auto",
    },
    galleryContainer: {
      height: "80%",
      width: "80%",
      margin: "2rem auto",
    },
  })
);
