import { createSlice } from "@reduxjs/toolkit";

const initStaffState = {
  staff: [
    { name: "BIKORIMANA Francois", role: "MD" },
    { name: "MUTAMURIZA REBECCA", role: "HR" },
    { name: "HABIMANA INNOCENT", role: "DAF" },
    { name: "NSABIMANA Sandrine", role: "Engineer & Officer" },
    { name: "NURU David", role: "Accountant" },
    { name: "TURATSINZE Eric", role: "IT" },
  ],
};

const staffSlice = createSlice({
  name: "staff",
  initialState: initStaffState,
  reducers: {
    updateStaff(state, action) {
      state.staff = action.payload;
    },
  },
});

export const staffActions = staffSlice.actions;

export default staffSlice.reducer;
