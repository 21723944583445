import { Typography } from "@material-ui/core";
import AdminLayout from "./layout";

const AdminHardwarePage = (props) => {
  return (
    <AdminLayout selected={3}>
      <Typography>Hardware Page</Typography>
    </AdminLayout>
  );
};

export default AdminHardwarePage;
