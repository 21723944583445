import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home/Home";
import ContactUsPage from "../pages/ContactUs/ContactUsPage";
import ShopPage from "../pages/Shop/ShopPage";
import ServicesPage from "../pages/Services/ServicesPage";
import ServicePage from "../pages/Services/ServicePage";
import AboutUsPage from "../pages/AboutUs/AboutUsPage";
import AdminPage from "../pages/Admin";
import AdminServicePage from "../pages/Admin/services";
import AdminCategoriesPage from "../pages/Admin/categories";
import AdminHardwarePage from "../pages/Admin/hardware";

const Index = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/about" exact element={<AboutUsPage />} />
      <Route path="/contact" exact element={<ContactUsPage />} />
      <Route path="/shop" exact element={<ShopPage />} />
      <Route path="/services" exact element={<ServicesPage />} />
      <Route path="/services/:id" exact element={<ServicePage />} />
      <Route path="/admin/services" exact element={<AdminServicePage />} />
      <Route path="/admin/categories" exact element={<AdminCategoriesPage />} />
      <Route path="/admin/hardware" exact element={<AdminHardwarePage />} />
      <Route path="/admin" exact element={<AdminPage />} />
    </Routes>
  );
};

export default Index;
