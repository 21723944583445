import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { SERVER_URL } from "../helpers/constants";

const initImageState = {};

const staffSlice = createSlice({
  name: "image",
  initialState: initImageState,
  reducers: {
    fetching(state) {
      state.isFetching = true;
    },
    setImages(state, action) {
      state.images = action.payload;
      state.isFetching = false;
    },
    setService(state, action) {
      state.service = action.payload;
      state.isFetching = false;
    },
  },
});

export const serviceActions = staffSlice.actions;

export default staffSlice.reducer;

export const getImages = (query) => {
  return async (dispatch) => {
    try {
      dispatch(serviceActions.fetching());
      const services = await axios({
        method: "GET",
        url: `${SERVER_URL}/api/v1/main/image?${query}`,
      });
      dispatch(serviceActions.setImages(services.data.data.data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getService = (id) => {
  return async (dispatch) => {
    try {
      dispatch(serviceActions.fetching);
      const service = await axios({
        method: "GET",
        url: `${SERVER_URL}/api/v1/main/service/${id}`,
      });
      dispatch(serviceActions.setService(service.data.data.data));
    } catch (err) {
      console.log(err);
    }
  };
};
