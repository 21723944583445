import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import { useShopPageStyles } from "../pages/Shop/shop.styles";

const PaintCard = (props) => {
  const classes = useShopPageStyles();

  return (
    <Card className={classes.paintCard}>
      <CardMedia
        className={classes.paintCardImage}
        component="img"
        height="140"
        image={props.image}
        alt="paint"
      />
      <CardContent className={classes.cardContent}>
        <Grid container spacing={0}>
          <Grid item xs={9}>
            <Typography className={classes.cardPrimaryLabel}>
              {props.name}
            </Typography>
            <Typography className={classes.cardSubTitle}>
              {props.price}, Negotialbe
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Box className={classes.colorBox}></Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PaintCard;
