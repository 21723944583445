import { Box, Button, Typography } from "@material-ui/core";
import { useHeaderStyles } from "./header.styles";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import slider1 from "../../../../assets/images/header_slider3.jpeg";
import slider2 from "../../../../assets/images/maintenance-1.jpeg";
import slider3 from "../../../../assets/images/header_slider1.jpeg";
import slider4 from "../../../../assets/images/shop-10.jpeg";
import slider5 from "../../../../assets/images/shop-11.jpeg";

const Header = () => {
  const slideImages = [
    {
      url: slider1,
    },
    {
      url: slider2,
    },
    {
      url: slider3,
    },
    {
      url: slider4,
    },
    {
      url: slider5,
    },
  ];
  const classes = useHeaderStyles();
  return (
    <Box className={classes.header}>
      {/* <img className={classes.headerImg} src={headerPic} alt="logo" /> */}
      <div className="slide-container">
        <Slide>
          {slideImages.map((slideImage, index) => (
            <div className="each-slide" key={index}>
              <div
                style={{
                  backgroundImage: `url(${slideImage.url})`,
                  backgroundSize: "cover",
                  height: "84vh",
                  width: "100vw",
                }}
              ></div>
            </div>
          ))}
        </Slide>
      </div>
      <Box className={`${classes.bb} ${classes.center}`}>
        <Box>
          <Typography className={classes.headerText}>
            CIVIL ENGINEERING
          </Typography>
          <Typography className={classes.headerText}>
            MULTI SERVICES LTD
          </Typography>
          <Box className={classes.centerHorizontally}>
            <Button className={classes.headerButton} variant="contained">
              DISCOVER
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
