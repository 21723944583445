import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  primaryColor,
  primaryGrey,
  primaryTextColor,
} from "../../helpers/constants";
// import { primaryColor } from "./components/colors";

export const useAboutUsPageStyles = makeStyles((Theme) =>
  createStyles({
    aboutUs: {
      height: "100vh",
      paddingTop: "2rem",
      backgroundColor: primaryGrey,
    },
    subHeader: {
      color: primaryColor,
      fontWeight: "bold",
      textAlign: "center",
    },
    header: {
      textAlign: "center",
      fontSize: "2rem",
      fontWeight: "bold",
    },
    teamContainer: {
      width: "90%",
      margin: "0 auto",
    },
    ourVision: {
      backgroundColor: primaryGrey,
      height: "40vh",
    },
    ourMission: {
      backgroundColor: primaryGrey,
      height: "40vh",
    },
    userCard: {
      backgroundColor: "white",
      borderRadius: "1rem",
      width: "100%",
      height: "17rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxShadow: "0 5px 8px #9E9E9E",
    },
    photoAvatar: {
      height: "11rem",
      width: "11rem",
      margin: "1rem auto",
      backgroundColor: "white",
    },
    photo: {
      maxHeight: "100%",
      maxWidth: "100%",
    },
    name: {
      fontWeight: "bold",
      fontStyle: "italic",
    },
    role: {
      color: primaryTextColor,
      fontStyle: "italic",
    },
  })
);
