import Layout from "../../components/Layout";
import { Typography, Box, FormControl, InputAdornment, Input } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search'
import { useShopPageStyles } from "./shop.styles";
import PaintCard from '../../components/PaintCard';
import basco from '../../assets/images/basco.jpeg';
import plascon from '../../assets/images/plasconlogo.png';
import fastDry from '../../assets/images/FastDry.png';
import budget from '../../assets/images/budgetemulsion.jpeg';
import vinylmatt from '../../assets/images/vinylmatt.jpeg';

const ShopPage = () => {
  const classes = useShopPageStyles();
  return (
    <Layout select={2}>
      <Box className={classes.shopContainer}>
      {/* <Box>
      <Typography>Heading</Typography>
      </Box> */}
      <Box className={classes.searchContainer}>
      <FormControl variant="standard">
            <Input
              className={classes.productSearch}
              placeholder="Search Product"
              disableUnderline={true}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                  {/* color="#9E9E9E" */}
                </InputAdornment>
              }
            />
          </FormControl>
      </Box>
      <Box className={classes.categoriesContainer}>
      <Typography>Categories</Typography>
      </Box>
      <Box>
      <Box className={classes.productsContainer}>
      <PaintCard name="Fast Dry White 4L" price="18,500 Rwf" image={fastDry}/>
      <PaintCard name="Vinyl Matt Yellow 4L" price="32,000 Rwf" image={vinylmatt} />
      <PaintCard name="Fast Dry Red 4L" price="18,500 Rwf" image={budget} />
      <PaintCard name="Orange White 4L" price="19,200 Rwf" image={vinylmatt} />
      <PaintCard name="Budget Emulsion Green 4L" price="22,500 Rwf" image={budget} />
      <PaintCard name="Orange White 4L" price="19,200 Rwf" image={vinylmatt} />
      <PaintCard name="Budget Blue 4L" price="22,500 Rwf" image={budget} />
      </Box>
      </Box>
      
      <Box>
      <Typography className={classes.header}>Our Patners</Typography>
      <Box className={classes.patnersContainer}>
        <Box className={classes.partnerImageBox}>
        <img className={classes.partnerImage} alt="patners" src={plascon}/>
        </Box>
        <Box className={classes.partnerImageBox}>
        <img className={classes.partnerImage} alt="patners" src={basco}/>
        </Box>
      </Box>
      </Box></Box>
    </Layout>
  );
};

export default ShopPage;