import { Typography, Avatar, Box } from "@material-ui/core";
import { useAboutUsPageStyles } from "../pages/AboutUs/aboutus.styles";

const UserCard = (props) => {
    const classes = useAboutUsPageStyles();
    return (
        <Box className={classes.userCard}>
            <Avatar className={classes.photoAvatar}
            >
                <img className={classes.photo} src={props.img} alt="team-player" />
            </Avatar>
            <Typography className={classes.name}>{props.name}</Typography>
            <Typography className={classes.role}>{props.role}</Typography>
        </Box>
    );
}

export default UserCard;