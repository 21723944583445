import * as React from "react";
import { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Button,
  ImageList,
  ImageListItem,
  NativeSelect,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import AdminLayout from "./layout";
import { useAdminStyles } from "./admin.styles";
import UploadImageDialog from "./upload_image_dialog";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { getServices, getService } from "../../store/services";

const AdminServicePage = (props) => {
  const classes = useAdminStyles();
  const dispatch = useDispatch();
  const serviceState = useSelector((state) => state.service);
  const [uploadImageDialogOpen, setUploadImageDialogOpen] = useState(false);
  const [service, setService] = useState("");
  const [isInit, setIsInit] = useState(true);

  const handleClickOpen = () => {
    setUploadImageDialogOpen(true);
  };

  const handleClose = () => {
    setUploadImageDialogOpen(false);
  };

  const serviceChangedHandler = (e) => {
    setService(e.target.value);
  };

  useEffect(() => {
    if (!service) dispatch(getServices("?fields=name"));
  }, [dispatch, service]);

  useEffect(() => {
    if (serviceState.services) setService(serviceState.services[0]._id);
  }, [serviceState.services]);

  useEffect(() => {
    if (!isInit) {
      if (service) dispatch(getService(service));
    } else {
      setIsInit(false);
    }
  }, [dispatch, isInit, service]);

  return (
    <AdminLayout selected={1}>
      <Box className={classes.container}>
        {/* <div className="popup__item">
          <input
            className="form__input"
            onChange={serviceChangedHandler}
            list="siteslist"
            required
          />
          <datalist id="siteslist">
            {serviceState?.services?.map((service) => (
              <option
                key={service.name}
                data-value={service._id}
                value={service.name}
              />
            ))}
          </datalist>
        </div> */}
        <NativeSelect
          value={service}
          onChange={serviceChangedHandler}
          // input={<BootstrapInput />}
        >
          {serviceState?.services?.map((service) => (
            <option key={service._id} value={service._id}>
              {service.name}
            </option>
          ))}
        </NativeSelect>
        <Box className={classes.titleContainer}>
          <Typography className={classes.serviceName}>
            {serviceState?.service?.name}
          </Typography>
        </Box>
        <Box className={classes.coverIamgeContainer}>
          <img
            src={serviceState?.service?.imageCover?.url}
            alt="cover"
            className={classes.coverImage}
          />
        </Box>
        <Typography>{serviceState?.service?.description}</Typography>
        <Typography>Gallery</Typography>
        <Box className={classes.galleryContainer}>
          <ImageList rowHeight={180} className={classes.imageList} cols={4}>
            {serviceState?.service?.images?.map((item) => (
              <ImageListItem key={item.cloudinary_id} cols={item.cols || 1}>
                <img src={item.url} alt={item.cloudinary_id} />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
        <Box className={classes.center}>
          <Button
            className={classes.addImageButton}
            variant="contained"
            onClick={handleClickOpen}
            startIcon={<AddPhotoAlternateIcon />}
          >
            Add Image
          </Button>
        </Box>
        {uploadImageDialogOpen && (
          <UploadImageDialog
            open={uploadImageDialogOpen}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            service={service}
          />
        )}
      </Box>
    </AdminLayout>
  );
};

export default AdminServicePage;
