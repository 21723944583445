import * as React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  ImageList,
  ImageListItem,
} from "@material-ui/core";
import AdminLayout from "./layout";
import { useAdminStyles } from "./admin.styles";
import UploadImageDialog from "./upload_image_dialog";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { useSelector, useDispatch } from "react-redux";
import { getImages } from "../../store/images";

const AdminCategoriesPage = (props) => {
  const classes = useAdminStyles();
  const dispatch = useDispatch();
  const imagesState = useSelector((state) => state.image);
  const serviceState = useSelector((state) => state.service);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [category, setCategory] = useState("construction");

  const [uploadImageDialogOpen, setUploadImageDialogOpen] =
    React.useState(false);

  const handleClickOpen = () => {
    setUploadImageDialogOpen(true);
  };

  const handleClose = () => {
    setUploadImageDialogOpen(false);
  };

  const handleChange = (index, category) => {
    setSelectedCategory(index);
    setCategory(category);
    dispatch(getImages(`category=${category}`));
  };

  useEffect(() => {
    dispatch(getImages(`category=${category}`));
  }, [dispatch, category]);

  useEffect(() => {
    console.log("updatedNeeded ", serviceState.updateNeeded);
  }, [serviceState.updateNeeded]);

  return (
    <AdminLayout selected={2}>
      <Box className={classes.container}>
        <List className={classes.galleryCategories}>
          <ListItem
            onClick={() => handleChange(0, "construction")}
            selected={selectedCategory === 0}
            classes={{ selected: classes.listItemSelected }}
          >
            <ListItemText>CONSTRUCTION</ListItemText>
          </ListItem>
          <ListItem
            onClick={() => handleChange(1, "shop")}
            selected={selectedCategory === 1}
            classes={{ selected: classes.listItemSelected }}
          >
            <ListItemText>SHOP</ListItemText>
          </ListItem>
          <ListItem
            onClick={() => handleChange(2, "painting")}
            selected={selectedCategory === 2}
            classes={{ selected: classes.listItemSelected }}
          >
            <ListItemText>PAINTING</ListItemText>
          </ListItem>
        </List>
        {!imagesState.isFetching && (
          <Box className={classes.galleryContainer}>
            <ImageList rowHeight={180} className={classes.imageList} cols={4}>
              {imagesState?.images?.map((image) => (
                <ImageListItem
                  key={image?.cloudinary_id}
                  cols={image?.dimension[0] || 1}
                >
                  <img src={image?.url} alt={image.cloudinary_id} />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        )}
        {/* {imagesState.isFetching && (
          <Box className={classes.loadingContainer}>
            <Typography className={classes.loadingText}>loading...</Typography>
          </Box>
        )} */}
        <Box className={classes.center}>
          <Button
            className={classes.addImageButton}
            variant="contained"
            onClick={handleClickOpen}
            startIcon={<AddPhotoAlternateIcon />}
          >
            Add Image
          </Button>
        </Box>
        {uploadImageDialogOpen && (
          <UploadImageDialog
            open={uploadImageDialogOpen}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            category={category}
          />
        )}
      </Box>
    </AdminLayout>
  );
};

export default AdminCategoriesPage;
