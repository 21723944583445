import { useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import ServiceCard from "../../../../components/ServiceCard";
import { useSelector, useDispatch } from "react-redux";
import { getServices } from "../../../../store/services";
import { primaryColor } from "../../../../helpers/constants";
import { useServicesStyles } from "./services.styles";
// import { useNavigate } from "react-router-dom";
import shop from "../../../../assets/images/shop-6.jpeg";

const Services = () => {
  // let navigate = useNavigate();

  const dispatch = useDispatch();
  const services = useSelector((state) => state.service.services);

  useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  const classes = useServicesStyles();
  return (
    <Box className={classes.services}>
      <Typography className={classes.servicesHeader}>
        <span style={{ color: primaryColor }}>OUR</span> SERVICES
      </Typography>
      <Box className={classes.f}>
        {services &&
          services
            .slice(0, 3)
            .map((service, index) => (
              <ServiceCard
                id={service._id}
                key={index}
                image={service?.imageCover?.url}
                service={service.name}
              />
            ))}
      </Box>
      <Box className={classes.f}>
        {services &&
          services
            .slice(3)
            .map((service, index) => (
              <ServiceCard
                id={service._id}
                key={index}
                image={service?.imageCover?.url}
                service={service.name}
              />
            ))}
        <Card className={classes.serviceCard} onClick={() => {}}>
          <CardMedia
            className={classes.paintCardImage}
            component="img"
            height="187"
            image={shop}
            alt="paint"
          />
          <CardContent className={classes.cardContent}>
            <Typography className={classes.cardPrimaryLabel}>
              Hardware Retail and Supply
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Services;
