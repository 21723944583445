import { useEffect } from "react";
import Layout from "../../components/Layout";
import { Typography, Box, ImageList, ImageListItem } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { getService } from "../../store/services";
import { useSelector, useDispatch } from "react-redux";
import { useServicesPageStyles } from "./service.styles";
// import image2 from "../../assets/images/image2.jpeg";
// import image3 from "../../assets/images/image3.jpeg";
// import image4 from "../../assets/images/image4.jpeg";
// import image5 from "../../assets/images/image5.jpeg";
// import image6 from "../../assets/images/image6.jpeg";
// import image7 from "../../assets/images/image7.jpeg";

const ServicePage = () => {
  const classes = useServicesPageStyles();
  const service = useSelector((state) => state.service.service);
  // const service = useSelector((state) => state.service.service);
  const dispatch = useDispatch();
  let { id } = useParams();

  console.log(
    "service g g g g g g g g g g g g g gg g g g g g g g g g g  gg  ",
    service
  );
  // const itemData = [
  //   {
  //     img: image2,
  //     title: "Image",
  //     author: "author",
  //     cols: 2,
  //   },
  //   {
  //     img: image3,
  //     title: "Image",
  //     author: "author",
  //   },
  //   {
  //     img: image4,
  //     title: "Image",
  //     author: "author",
  //   },
  //   {
  //     img: image5,
  //     title: "Image",
  //     author: "author",
  //   },
  //   {
  //     img: image6,
  //     title: "Image",
  //     author: "author",
  //   },
  //   {
  //     img: image7,
  //     title: "Image",
  //     author: "author",
  //   },
  // ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // dispatch(getServices());
    if (id) dispatch(getService(id));
  }, [dispatch, id]);

  return (
    <Layout select={2}>
      {service && (
        <div>
          <Box className={classes.imageHeader}>
            <Box className={classes.titleContainer}>
              <Typography className={classes.serviceTitle}>
                {service.name}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.descriptionContainer}>
            <Typography>{service.description}</Typography>
          </Box>
          <Box className={classes.galleryContainer}>
            <ImageList rowHeight={180} className={classes.imageList} cols={4}>
              {service.images.map((item, index) => (
                <ImageListItem key={index} cols={1}>
                  <img src={item.url} alt={"item" + index} />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        </div>
      )}
    </Layout>
  );
};

export default ServicePage;
