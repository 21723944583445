import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { useGalleryStyles } from "./gallery.styles";
import { useSelector, useDispatch } from "react-redux";
import { getImages } from "../../../../store/images";

const Gallery = () => {
  const dispatch = useDispatch();
  const imagesState = useSelector((state) => state.image);
  console.log("imagesState in widgets   s s s ", imagesState);
  const classes = useGalleryStyles();
  const [selectedCategory, setSelectedCategory] = useState(0);

  const handleChange = (index, category) => {
    setSelectedCategory(index);
    dispatch(getImages(`category=${category}`));
  };
  useEffect(() => {
    dispatch(getImages(`category=construction`));
  }, [dispatch]);

  return (
    <Box className={classes.gallery}>
      <Typography className={classes.galleryHeader}>GALLERY</Typography>
      <List className={classes.galleryCategories}>
        <ListItem
          onClick={() => handleChange(0, "construction")}
          selected={selectedCategory === 0}
          classes={{ selected: classes.listItemSelected }}
        >
          <ListItemText>CONSTRUCTION</ListItemText>
        </ListItem>
        <ListItem
          onClick={() => handleChange(1, "shop")}
          selected={selectedCategory === 1}
          classes={{ selected: classes.listItemSelected }}
        >
          <ListItemText>SHOP</ListItemText>
        </ListItem>
        <ListItem
          onClick={() => handleChange(2, "painting")}
          selected={selectedCategory === 2}
          classes={{ selected: classes.listItemSelected }}
        >
          <ListItemText>PAINTING</ListItemText>
        </ListItem>
        <ListItem
          onClick={() => handleChange(3)}
          selected={selectedCategory === 3}
          classes={{ selected: classes.listItemSelected }}
        >
          <ListItemText>ALL</ListItemText>
        </ListItem>
      </List>
      {!imagesState.isFetching && (
        <Box className={classes.galleryContainer}>
          <ImageList rowHeight={180} className={classes.imageList} cols={4}>
            {imagesState?.images?.map((image) => (
              <ImageListItem
                key={image.cloudinary_id}
                cols={image.dimension[0] || 1}
              >
                <img src={image.url} alt={image.cloudinary_id} />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      )}
      {imagesState?.isFetching && (
        <Box className={classes.loadingContainer}>
          <Typography className={classes.loadingText}>loading...</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Gallery;
