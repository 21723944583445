import * as React from "react";
import { useState } from "react";
import axios from "axios";
import { Button, Box, CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
// import SaveIcon from "@material-ui/icons/Save";
// import UploadFileIcon from "@material-ui/icons/UploadFile";
import { useDispatch } from "react-redux";
import { serviceActions } from "../../store/services";
import { useAdminStyles } from "./admin.styles";
import { SERVER_URL } from "../../helpers/constants";

const UploadImageDialog = (props) => {
  const dispatch = useDispatch();
  const [fileInputState, setFileInputState] = useState("");
  const [previewSource, setPreviewSource] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [uploading, setUploading] = useState(false);

  const classes = useAdminStyles();

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
    setSelectedFile(file);
    setFileInputState(e.target.value);
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };
  };

  const handleSubmitFile = (e) => {
    e.preventDefault();
    if (!selectedFile) return;
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onloadend = () => {
      uploadImage(reader.result);
    };
    reader.onerror = () => {
      console.error("AAAAH!");
    };
  };

  const uploadImage = async (base64EncodedImage) => {
    let data = { image: base64EncodedImage };
    if (props.category) data.category = props.category;
    if (props.service) data.service = props.service;
    setUploading(true);
    try {
      const image = await axios({
        url: `${SERVER_URL}/api/v1/main/image`,
        method: "POST",
        data,
      });
      if (props.service) {
        await axios({
          url: `${SERVER_URL}/api/v1/main/service/image-upload/${props.service}`,
          method: "POST",
          data: {
            image: image.data.data.data._id,
          },
        });
      }
      setUploading(false);
      props.handleClose();
      dispatch(serviceActions.setUpdateNeeded);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.alertDialogTitle}>
          Upload Image
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.contentContainer}>
            {previewSource && (
              <Box className={classes.imagePreview}>
                <img
                  className={classes.image}
                  src={previewSource}
                  alt="chosen"
                />
              </Box>
            )}
            <form className={classes.actions} onSubmit={handleSubmitFile}>
              {/* <input
                id="fileInput"
                type="file"
                name="image"
                onChange={handleFileInputChange}
                value={fileInputState}
                className={classes.image}
              /> */}
              <input
                accept="image/*"
                className={classes.input}
                onChange={handleFileInputChange}
                id="contained-button-file"
                value={fileInputState}
                multiple
                type="file"
              />
              <Box className={classes.imageActions}>
                <label htmlFor="contained-button-file">
                  <Button
                    startIcon={<AttachFileIcon />}
                    className={classes.pickImageButton}
                    color="primary"
                    component="span"
                  >
                    Choose File
                  </Button>
                </label>
                {!uploading && (
                  <Button
                    className={classes.addImageButton}
                    startIcon={<CloudUploadIcon />}
                    type="submit"
                  >
                    Upload
                  </Button>
                )}
                {uploading && (
                  <Button
                    className={classes.addImageButton}
                    disabled={true}
                    type="submit"
                  >
                    <CircularProgress className={classes.progressIndicator} />
                  </Button>
                )}
              </Box>
            </form>
          </Box>
        </DialogContent>
        {/* <DialogActions className={classes.actions}>
          <Button onClick={props.handleClose}>Discard</Button>
          <Button
            className={classes.addImageButton}
            // onClick={props.handleClose}
            autoFocus
            type="submit"
          >
            Save
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default UploadImageDialog;
