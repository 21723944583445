import AboutUs from "./sections/AboutUs/AboutUs";
import Services from "./sections/Services/Services";
import Header from "./sections/Header/Header";
import Layout from "../../components/Layout";
import Gallery from "./sections/Gallery/Gallery";

const Home = () => {
  return (
    <Layout select={0}>
      <Header />
      <AboutUs />
      <Services />
      <Gallery />
    </Layout>
  );
};

export default Home;
