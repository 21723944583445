import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  primaryGrey,
  primaryTextColor,
  primaryColor,
} from "../../../../helpers/constants";

export const useAboutUsStyles = makeStyles((Theme) =>
  createStyles({
    aboutUs: {
      height: "100vh",
      backgroundColor: primaryGrey,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    aboutUsContainer: {
      width: "85%",
      // margin: "0 auto",
    },
    aboutUsRight: {},
    aboutUsHeading: {
      color: "#7dc22b",
      fontWeight: "bold",
    },
    aboutUsBigText: {
      fontSize: "2.5rem",
      fontWeight: "bold",
    },
    aboutUsParagraph: {
      marginTop: "1rem",
      marginBottom: "2rem",
      color: primaryTextColor,
    },
    aboutUsContactBtn: {
      backgroundColor: primaryColor,
      color: "white",
    },
    tile1: {
      backgroundColor: "cyan",
      height: "24rem",
      border: "4px solid",
      borderColor: primaryTextColor,
      "&:hover": {
        borderColor: primaryColor,
      },
    },
    imagesRight: {
      height: "32rem",
      display: "flex",
      flexDirection: "column",
    },
    imageRight: {
      height: "24rem",
      width: "100%",
    },
    up: {
      backgroundColor: "yellow",
      flexGrow: "1",
      marginBottom: "0.5rem",
      border: "4px solid",
      borderColor: primaryTextColor,
      "&:hover": {
        borderColor: primaryColor,
      },
    },
    down: {
      backgroundColor: "black",
      flexGrow: "1",
      border: "4px solid",
      borderColor: primaryTextColor,
      "&:hover": {
        borderColor: primaryColor,
      },
    },
    headerNavLogo: {
      height: "100%",
      width: "100%",
    },
  })
);
