import { Typography } from "@material-ui/core";
import AdminLayout from "./layout";

const AdminPage = (props) => {
  return (
    <AdminLayout selected={0}>
      <Typography>Main Page</Typography>
    </AdminLayout>
  );
};

export default AdminPage;
