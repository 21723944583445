import Layout from "../../components/Layout";
import { Typography, Grid, Box } from "@material-ui/core";
import eric from '../../assets/images/eric.jpeg'
import UserCard from "../../components/UserCard";
import { useAboutUsPageStyles } from "./aboutus.styles";

const AboutUsPage = () => {
  const classes = useAboutUsPageStyles()

  return (
    <Layout select={1}>
      <Box className={classes.ourVision}>
          <Typography className={classes.header}>OUR Vision</Typography>
        </Box>
        <Box className={classes.ourVision}>
          <Typography className={classes.header}>OUR Mission</Typography>
        </Box>
      <Box className={classes.aboutUs}>
      <Typography className={classes.subHeader}>CEMS LTD</Typography>
      <Typography className={classes.header}>MEET OUR TEAM</Typography>
      <Grid container spacing={3} className={classes.teamContainer}>
        <Grid item xs={3}>
          <UserCard img={eric} name="SHEJA Gabriel" role="Managing Director"/>
        </Grid>
        <Grid item xs={3}>
        <UserCard img={eric} name="MUGISHA David" role="Accountant"/>
        </Grid>
        <Grid item xs={3}>
        <UserCard img={eric} name="IRAKIZA Daniel" role="Accountant"/>
        </Grid>
        <Grid item xs={3}>
        <UserCard img={eric} name="SHUMBUSHO Simon" role="Engineer"/>
        </Grid>
      </Grid>
      </Box>
    </Layout>
  );
};

export default AboutUsPage;
