import { makeStyles, createStyles } from "@material-ui/core/styles";
import { primaryColor } from "./helpers/constants";
// import { primaryColor } from "./components/colors";

export const useStyles = makeStyles((Theme) =>
  createStyles({
    headerNav: {
      height: "10vh",
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
    headerNavLogo: {
      height: "5rem",
      width: "5rem",
    },
    headerNavLeft: {
      display: "flex",
      alignItems: "center",
    },
    header: {
      // backgroundColor: "black",
      height: "90vh",
    },
    headerImg: {
      height: "100%",
      width: "100%",
    },
    list: {
      listStyle: "none",
      color: "black",
      paddingLeft: "15px",
    },
    listItem: {
      display: "flex",
      paddingTop: "3px",
      paddingBottom: "3px",
      alignItems: "center",
      "&:hover": {},
    },
    listItemIcon: {
      minWidth: "2rem",
    },
    linkItem: {
      width: "100%",
      textDecoration: "none",
      fontSize: "16px",
      marginLeft: "1rem",
      "&:hover": {},
    },
    link: {
      textDecoration: "none",
      dispay: "flex",
      color: "black",
    },
    contactBtn: {
      marginLeft: "1rem",
      backgroundColor: primaryColor,
      color: "white",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "#73ab30",
      },
    },
    mg1: {
      margin: "15px 0",
    },
    navList: {
      display: "flex",
      flexDirection: "row",
      padding: 0,
    },
    listItemSelected: {
      color: primaryColor,
      borderBottom: "2px solid #7dc22b",
      backgroundColor: "#fff !important",
    },
    root: {
      "&$selected": {
        backgroundColor: "red",
        "&:hover": {
          backgroundColor: "yellow",
        },
      },
    },
    contactLink: {
      color: "white",
      textDecoration: "none",
    },
  })
);
