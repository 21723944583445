import { makeStyles, createStyles } from "@material-ui/core/styles";
import { primaryColor } from "../../../../helpers/constants";

export const useHeaderStyles = makeStyles((Theme) =>
  createStyles({
    headerNav: {
      height: "10vh",
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
    headerNavLogo: {
      height: "5rem",
      width: "5rem",
    },
    headerNavLeft: {
      display: "flex",
      alignItems: "center",
    },
    header: {
      // backgroundColor: "black",
      height: "90vh",
      position: "relative",
    },
    headerImg: {
      height: "100%",
      width: "100%",
    },
    bb: {
      height: "90vh",
      width: "100%",
      zIndex: "100",
      position: "absolute",
      top: "0px",
      left: "0px",
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    centerHorizontally: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    headerText: {
      color: "white",
      fontSize: "4.5rem",
      fontWeight: "bold",
      textAlign: "center",
    },
    headerButton: {
      backgroundColor: primaryColor,
      color: "white",
      fontSize: "1.3rem",
      fontWeight: "bold",
      textAlign: "center",
      marginTop: "1rem",
    },
  })
);
