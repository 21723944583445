import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  primaryColor,
  footerPrimaryBg,
  footerLightBg,
  primaryGrey,
  primaryBackground,
} from "../../helpers/constants";
// import { primaryColor } from "./components/colors";

export const useAdminStyles = makeStyles((Theme) =>
  createStyles({
    container: {
      padding: "2rem 3rem",
    },
    sideNav: {
      backgroundColor: footerPrimaryBg,
      height: "100vh",
    },
    titleContainer: {
      width: "50%",
      margin: "0 auto",
    },
    serviceName: {
      textAlign: "center",
      fontWeight: "700",
      fontSize: "2rem",
    },
    coverIamgeContainer: {
      width: "60%",
      height: "50vh",
      backgroundColor: "cyan",
      margin: "1rem auto",
    },
    link: {
      color: "white",
      textDecoration: "none",
    },
    galleryCategories: {
      display: "flex",
      flexDirection: "row",
      padding: 0,
    },
    alertDialogTitle: {
      fontSize: "2.5rem",
      textAlign: "center",
    },
    imagePreview: {
      width: "80%",
      height: "18rem",
      margin: "0 auto 1rem auto",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    coverImage: {
      width: "100%",
      height: "50vh",
      objectFit: "cover",
    },
    listItemSelected: {
      color: primaryColor,
      backgroundColor: footerLightBg,
    },
    addImageButton: {
      color: "white",
      fontWeight: "600",
      borderRadius: "0.5rem",
      padding: "5px 30px",
      backgroundColor: primaryColor,
      "&:hover": {
        backgroundColor: primaryColor,
      },
    },
    pickImageButton: {
      color: primaryColor,
      backgroundColor: primaryBackground,
      "&:hover": {
        backgroundColor: primaryGrey,
      },
    },
    center: {
      width: "100%",
      height: "4rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dialogContent: {
      width: "41vw",
      height: "25rem",
      padding: "0px !important",
    },
    contentContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    actions: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    progressIndicator: {
      color: "white",
    },
    input: {
      display: "none",
    },
    imageActions: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "37vw",
    },
    parentContainer: {
      margin: 0,
      height: "100vh",
      overflow: "scroll",
    },
    rightContainer: {
      height: "100vh",
      overflow: "scroll",
    },
  })
);
